import { http } from './config'
import authHeader from './auth-header';

export default {

    lista: (situacao) => {
        return http.get('listacupom/'+situacao , { headers: authHeader() })
    },

    criarCupom: (lote) => {
        return http.post('criarCupom', lote, { headers: authHeader() })
    },
   

}
