<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section">

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{alert_msg_descricao}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



<v-dialog 
      v-model="dialogAdd"
      persistent
      max-width="600px" 
    >
    
      <v-card>
         <v-toolbar color="success" dark> Criar Novo Lote</v-toolbar>
        <br/>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" > 

            <v-row>
              
              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-text-field prepend-icon="mdi-calculator" v-model="cupom.qtd" label="Quantidade de Cupons*" min="1" type="number" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-dialog
                    ref="dialogi"
                    v-model="date_modal_i"
                    :return-value.sync="cupom.validade"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense :rules="[v => !!v || 'Campo Obrigatório']"
                        v-model="cupom.validade"
                        label="Validade - Início"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="cupom.validade"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="date_modal_i = false"
                      >
                        Fechar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogi.save(currentDate(cupom.validade));"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

              </v-col>


              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-text-field prepend-icon="mdi-cash" v-model="cupom.desconto_adesao" label="Desconto Adesão (%) *" min="0"  type="number" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-text-field prepend-icon="mdi-cash" v-model="cupom.desconto_mensalidade" label="Desconto Mensalidade (%) *" min="0"  type="number" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>
         
       


              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-container
                    class="px-0"
                    fluid
                  >
                    <v-checkbox
                      v-model="cupom.sem_carencia_titular" label="Liberar Carência do TItular">
                      </v-checkbox>
                  </v-container>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-container
                  class="px-0"
                  fluid
                >
                  <v-checkbox
                    v-model="cupom.sem_carencia_dependene" label="Liberar Carência do Dependente">
                    </v-checkbox>
                </v-container>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                 <v-container
                  class="px-0"
                  fluid
                >
                  <v-checkbox
                    v-model="cupom.sem_carencia_agregado" label="Liberar Carência do Agregado">
                    </v-checkbox>
                </v-container>
              </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
                  <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="success"  text
            @click="dialogAdd = false">
            Fechar
          </v-btn>

          <v-btn :loading="showload"
            color="success"  @click="enviar">

           <v-icon right dark>mdi-check</v-icon>
            Confirmar
          </v-btn>

        </v-card-actions>
        <br/>
      </v-card>
      
    </v-dialog>
    


<div class="card">

  

  <div class="card-body">
    <h4 class="card-title"></h4>
    <base-material-card
      icon="mdi-ticket"
      title="Cupons Cadastrados"
      class="px-5 py-3"
    >

  <v-switch
          v-model="select_status" @change="onChange($event)"
          label="Disponível"
          class="mt-2"
        ></v-switch>



      <v-data-table  :headers="fields" :items="cupons" item-key="codigo" class="elevation-0" :loading="loading">
        
         <template v-slot:item.sem_carencia_titular="{ item }">
            <v-chip
              :color="getBadge(item.sem_carencia_titular)"
              dark
            >
              {{getDescricaoStatus(item.sem_carencia_titular)}}                    
            </v-chip>
        </template>

         <template v-slot:item.sem_carencia_dependene="{ item }">
            <v-chip
              :color="getBadge(item.sem_carencia_dependene)"
              dark
            >
              {{getDescricaoStatus(item.sem_carencia_dependene)}}                    
            </v-chip>
        </template>

      <template v-slot:item.sem_carencia_agregado="{ item }">
            <v-chip
              :color="getBadge(item.sem_carencia_agregado)"
              dark
            >
              {{getDescricaoStatus(item.sem_carencia_agregado)}}                    
            </v-chip>
        </template>

      

      </v-data-table>

    </base-material-card>
      <br/>
     <v-row
      > 
        <v-btn  color="success text-white"   @click="dialogAdd = true">NOVO LOTE
          <v-icon
            right
            dark
          >
            mdi-ticket
          </v-icon>

        </v-btn>

        <download-excel :data = "this.json_data" name = "cupons.xls" :fields="json_fields"
> 
          <v-btn  color="purple text-white"  >Download excel
            <v-icon right dark>
              mdi-file-table
            </v-icon>

          </v-btn>

        </download-excel>

    </v-row>

  </div>
</div>
   
  </v-container>
</template>
<script>
  import Cupom from '../../../services/cupom'
  import moment from 'moment'




  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    data: () => ({
      //items: items.map((item, id) => { return {...item, id}}),
      details: [],
      collapseDuration: 0,
      periodo_selecionado: 'Disponível',
      show: true,
      loading: false,
      date_modal_i: false,
      dialogAdd: false,
      alert_msg_descricao: '',
      alert_msg: false,
      cupom:{
          qtd: '',
          validade: '',
          desconto_adesao: '',
          desconto_mensalidade: '',
          sem_carencia_titular: false,
          sem_carencia_dependene: false,
          sem_carencia_agregado: false
      },
      periodos: [
        'Disponível',
        'Usado'
      ],

     json_fields: {
      "CUPOM": "codigo",
      "Desconto Adesão (%)": "desconto_adesao",
      "Desconto Mensalide (%)": "desconto_mensalidade",
      "Liberar Carência Titular": {
        field: "sem_carencia_titular",
        callback: (value) => {
        switch (value) {
          case true: return 'Sim'
          case false: return 'Não'
          default: ''
        }
        },
      },
      "Liberar Carência Dependente": {
        field: "sem_carencia_dependene",
        callback: (value) => {
        switch (value) {
          case true: return 'Sim'
          case false: return 'Não'
          default: ''
        }
        },
      },
      "Liberar Carência Agregado": {
        field: "sem_carencia_agregado",
        callback: (value) => {
        switch (value) {
          case true: return 'Sim'
          case false: return 'Não'
          default: ''
        }
        },
      },

       "Validade": {
        field: "validade",
        callback: (value) => {
          return  moment(value).format('DD/MM/YYYY')
        },
      },


    },
     json_data: [],
     select_status: true,
       fields:[
        { text: 'Código', sorter: true, filter: false, value:'codigo', class: 'title'},
        { text: 'Desc. Adesão' , sorter: false, filter: false, value:'desconto_adesao', class:'text-black-50 title'},
        { text: 'Desc. Mensalidade', sorter: false, filter: false, value:'desconto_mensalidade', class:'title'},
        { text: 'Liberar Carência Titular', sorter: false, filter: false, value:'sem_carencia_titular', class:'title'},
        { text: 'Liberar Carência Dependente', sorter: false, filter: false, value:'sem_carencia_dependene', class:'title'},
        { text: 'Liberar Carência Agregado', sorter: false, filter: false, value:'sem_carencia_agregado', class:'title'},
        { text: 'Validade', sorter: true, filter: false, value:'validade', class:'title'},
      ],
      cupons: [],
    
      rules: [
        value => !!value || 'Required.',
      ],


      msg_dialog: '',
      dialog: false,
      checkboxNames: ['01', '02', '03', '04'],
    }),

    mounted () {
      this.listar(this.periodo_selecionado)
    },

    methods: {

      onSubmit(event) {
         alert('subm')
      },

     


      listar (valor) {
          this.loading = true

        Cupom.lista(valor).then(response => {
            this.cupons = response.data
            this.json_data = response.data
        }).catch(e => {
            if(e.response.status === 401){
            //this.$store.dispatch('auth/logout');
            //this.$router.push('/pages/login');
            }

        }).finally(() => {
             this.loading = false

        })
      },

      onChange(event) {
        var value = event
        if(value == true){
          value = 'Disponível'
        }
        if(value == false){
          value = 'Usado'
        }

        this.listar(value)
      },
     
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
      },

      enviar() {
          if(this.$refs.form_card.validate()){
                this.showload = true;
                this.dialogAdd == true;

                Cupom.criarCupom(this.cupom).then(response => {
                          this.cupons = response.data
                          if(response.status == 200){
                              this.cupom.qtd = 1,
                              this.alert_msg = true,
                              this.alert_msg_descricao = 'Novos(s) Cupons criado com sucesso!'
                              this.cupom.desconto_adesao = 0,
                              this.cupom.desconto_mensalidade = 0,
                              this.cupom.sem_carencia_titular = false,
                              this.cupom.sem_carencia_dependene = false,
                              this.cupom.sem_carencia_agregado = false,
                              this.cupom.validade = '',
                              this.dialogAdd = false
                          }


                        }).catch(e => {
                              this.dialogAdd = false,
                              this.alert_msg = true,
                              this.alert_msg_descricao = 'Ops! Problemas para cadastrar lote de cupons'
                              this.showload = false;

                          
                        }).finally(() => {
                             this.dialogAdd = false
                             this.showload = false;


                        })
          }                  
      },

      mudarStatus(pos, item){
            Usuario.updateStatusUsuario(item.id).then(response => {
                        this.usuarios.indexOf(item)
          item.ativo = response.data;
          Object.assign(this.usuarios[pos], item)

            //this.usuarios.push(item)


        }).catch(e => {
           
        }).finally(() => {

        })
      },

      setDisabled: function(status){
        return status
      },
      
     

      setEnable: function(status){
        return !status
      },

      getBadge (status) {
      switch (status) {
        case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'danger'
      }
      },

      toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },

    
    
    currentDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },

      getStatus(status) {
      switch (status) {
        case true: return 'Usado'
        case false: return 'Disponível'
        default: ''
      }
    },

    getSituacaoStatus (status) {
      switch (status) {
        case true: return 'success'
        case false: return 'danger'
        default: 'danger'
      }
      },

      getDescricaoStatus(status) {
      switch (status) {
        case true: return 'Sim'
        case false: return 'Não'
        default: ''
      }
    },

    

    }
  }

</script>
